import React from 'react';

import { Link } from "gatsby"
import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';

import avatar from '../assets/images/jess.png';
import demoImage1 from '../assets/images/demo-image-01.jpg';
import demoImage2 from '../assets/images/demo-image-02.jpg';
import bgMaster from '../assets/images/bg-masthead.jpg';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">{config.heading}</h1>
          <h2 className="text-white mx-auto mt-2 mb-5">
            {config.subHeading}
          </h2>
          <Scroll type="id" element="about">
            <a href="#about" className="btn btn-primary">
              About
            </a>
          </Scroll>
        </div>
      </div>
    </header>

    <section id="about" className="about-section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className="text-white mb-4">About Me</h2>
            <p className="text-white-50">
              Hi, I'm Jess. I live in London and currently work as an Application Support Analyst. 
              I'm learning to code in my free time to make websites.
              I love travelling, yoga, tango, Thai food, ice cream and sloths.
              Take a look at some of my projects below.
            </p>
          </div>
        </div>
        <img src={avatar} className="img-fluid pb-5" alt="" />
      </div>
    </section>

    <section id="projects" className="projects-section bg-light">
      <div className="container">
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div className="col-xl-8 col-lg-7">
            <img className="img-fluid mb-3 mb-lg-0" src={bgMaster} alt="" />
          </div>
          <div className="col-xl-4 col-lg-5">
            <div className="featured-text text-center text-lg-left">
              <h4>Frontend Projects</h4>
              <p className="text-black-50 mb-0">
                <ul>
                  <li>Down Dog Yoga Studio <a href="https://www.downdogyogalondon.co.uk" target="blank">www.downdogyogalondon.co.uk</a></li>
                  <li>The Guinea Pig Cafe (published without at domain atm) <a href="https://master.d3de10o81czq0u.amplifyapp.com/" target="blank">www.guineapigcafe.co.uk</a></li>

                </ul>
                
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-6">
            <img className="img-fluid" src={demoImage1} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-left">
                  <h4 className="text-white">Artwork</h4>
                  <p className="mb-0 text-white-50">
                    I also love drawing, painting and arts and crafts things. Check out some of my <Link to="/artwork">artwork</Link>.
                  </p>
                  <hr className="d-none d-lg-block mb-0 ml-0" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters">
          <div className="col-lg-6">
            <img className="img-fluid" src={demoImage2} alt="" />
          </div>
          <div className="col-lg-6 order-lg-first">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-right">
                  <h4 className="text-white">Photography</h4>
                  <p className="mb-0 text-white-50">
                    Take a look at some of my <Link to="/photos">photos</Link>. 
                  </p>
                  <hr className="d-none d-lg-block mb-0 mr-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Subscribe />
    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
